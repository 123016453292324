<template>
  <b-card class="max-w-xl">
    <GdprDocViewer />
  </b-card>
</template>

<script>

import {BCard} from "bootstrap-vue";
import GdprDocViewer from "@/views/gdpr/GdprDocViewer";

export default {
  name: 'GdprDocPage',
  components: {
    BCard,
    GdprDocViewer,
  },
}
</script>
